/*!
Theme Name: Hoogveenkerkhof
Theme URI: https://www.hoogveenkerkhof.nl
Author: Ruben Kaiser
Author URI: https://www.rubenkaiser.nl
Version: 1.0.0
*/

@use "sass:math";

@import 'variables';

@import 'reset';
@import 'global';
@import 'typography';
@import 'grid';

@import 'header';
@import 'button';
@import 'loading';
@import 'install';

.container {
  width: 94%;
  max-width: 920px;
  margin: 0 auto;
}

.hero {
  width: 100%;
  height: 300px;
  background-size: cover;
  background-position: center center;
  margin-top: -40px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 $base-spacing*2;
  div {
    text-align: center;
  }
  h1, p {
    color: #fff;
    margin: 0;
  }
}

.box {
  padding: $base-spacing*2;
  margin-bottom: $base-spacing*4 !important;
  position: relative;
  background-color: $box-background;
  border-radius: $button-radius;
  //box-shadow: 0 -2px 50px #e9e9e9;
  border: 1px solid darken($background, 7%);

  img {
    max-width: 100%;
  }

  @include responsive-from(m) {
    padding: $base-spacing*4 $base-spacing*3;
  }

  ul.links {
    margin: 0 $base-spacing*-2 $base-spacing*4 $base-spacing*-2;
    @include responsive-from(m) {
      margin: 0 $base-spacing*-3 $base-spacing*4 $base-spacing*-3;
    }
    li {
      a {
        display: block;
        border-top: 1px solid darken($background, 7%);
        padding: $base-spacing*2;
        @include responsive-from(m) {
          padding: $base-spacing*2 $base-spacing*3;
        }
        &:hover {
          background: darken($background, 4%);
        }
      }
      &:last-child {
        a, .list__inner {
          border-bottom: 1px solid darken($background, 7%);
        }
      }
    }
  }

  ul.albums {
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    li {
      flex-basis: 50%;
      flex-grow: 0;
      flex-shrink: 0;
      position: relative;
      overflow: hidden;
      a {
        margin-bottom: $base-spacing*2;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 1px solid #fff;
        border: 1px solid darken($background, 6%);
        background: darken($background, 2%);
        margin-right: 2px;
        flex-direction: column;
        figure {
          height: 140px;
          width: 100%;
          overflow: hidden;
          img {
            width: 100%;
            min-height: 140px;
          }
        }

        span {
          padding: 0 $base-spacing*2;
          line-height: 40px;
          display: block;
          width: 100%;
          font-size: 1.4rem;
          height: 40px;
          overflow: hidden;
        }
      }
    }
  }

  ul.album {
    margin: 0 0 $base-spacing*4 0;
    display: flex;
    flex-wrap: wrap;
    li {
      flex-basis: 100%;
      flex-grow: 0;
      flex-shrink: 0;

      @include responsive-from(m) {
        flex-basis: 50%;
      }
      position: relative;
      overflow: hidden;
      figure {
        line-height: 0;
        margin-bottom: 8px;
        border: 16px solid #f1f1f1;
        padding: 0 !important;
        @include responsive-from(m) {
          height: 320px;
          overflow: hidden;
          margin-bottom: 8px;
          margin-right: 8px;
        }
        img {
          width: 100%;
          height: auto;
          @include responsive-from(m) {
            min-height: 320px;
          }
        }
      }
    }
  }

}

.toggles {
  display: flex;
  button {
    font-weight: 500;
    display: inline-block;
    margin: 0 $base-spacing -1px 0;
    cursor: pointer;
    text-decoration: none;
    transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    background: #fff;
    color: $black;
    border-top: 1px solid darken($background, 7%);
    border-left: 1px solid darken($background, 7%);
    border-right: 1px solid darken($background, 7%);
    border-bottom: 1px solid #fff;
    font-family: $body-font;
    font-size: 20px;
    line-height: $button-height*1.2;
    height: $button-height*1.2;
    padding: 0 calc($button-height/2);
    border-radius: $button-radius $button-radius 0 0;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      display: none;
      @include responsive-from(m) {
        display: inline-block;
      }
    }
    span.tag {
      display: inline-block;
      line-height: $button-height*.8;
      height: $button-height*.8;
      width: $button-height*.8;
      padding: 0;
      background: $red;
      margin-left: $base-spacing;
      font-size: 12px;
      color: #fff;
      border-radius: $button-height*0.4;
      font-weight: 700;
    }
    &:focus {
      outline: 0;
    }

    &:hover {
      text-decoration: none;
    }

    &.inactive {
      background: $black;
      color: #fff;
      border-color: $black;
      border-bottom: 1px solid darken($background, 7%);
      &:hover {
        background: $green;
        border-color: $green;
        border-bottom-color: darken($background, 7%);
        text-decoration: none;
      }
    }
  }
}

.news-item {
  &--large {
    @include responsive-from(m) {
      display: flex;
    }
    figure {
      margin: 0 0 $base-spacing*2 0;
      @include responsive-from(m) {
        margin: 0 $base-spacing*4 0 0;
        flex-basis: 280px;
        flex-grow: 0;
        flex-shrink: 0;
        max-width: 40%;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
    section {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

article {
  ul {
    list-style: disc;
    margin-left: 2rem;
  }
  figure {
    padding: $base-spacing*2;
    border: 1px solid darken($background, 4%);
    display: inline-block;
    figcaption {
      font-size: 1.4rem;
      font-style: italic;
    }
  }
  hr {
    border-top: 1px solid darken($background, 4%);
    margin: $base-spacing*2 0;
  }
}

footer {
  background: $black;
  color: #fff;
  //margin: $base-spacing*8 auto 0 auto;
  padding: $base-spacing*8 0 $base-spacing*2 0;
  width: 100%;
  position: relative;
  ul {
    margin-bottom: $base-spacing*4;
    li {
      a {
        text-decoration: none;
        color: #fff;
        line-height: 3.6rem;
        &:hover {
          text-decoration: underline;
          color: #fff;
        }
      }
    }
  }

  .footer__links {
    margin-bottom: $base-spacing*2;
  }
  .footer__disclaimer {
    text-align: center;
    color: #666;
  }
}
