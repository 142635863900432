@use "sass:math";

// $menuBg: $orange;
// $menuColor: #fff;
// $menuDarken: 6%;
// $menuSubDarken: 2%;

$menuBg: lighten($blue, 14%); //#fff;
$menuColor: #fff;
$menuDarken: 8%;
$menuSubDarken: 2%;

.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100000;
  // backdrop-filter: blur(4px);

  height: 100px;
  @include responsive-from(m) {
    height: 140px;
  }

  &__top {
    height: 20px;
    background: darken($blue, 6%);
    @include responsive-from(m) {
      height: 60px;
      background: #fff;
    }
    div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 20px;
      @include responsive-from(m) {
        height: 60px;
      }
      p {
        margin: 0;
        text-align: right;
        display: none;
        color: $black;
        @include responsive-from(m) {
          display: inline;
        }
      }
    }
  }
  &__main {
    height: 80px;
    background: $blue;
    padding: 10px;
    > div {
      position: relative;
      width: 100%;
      height: 80px;
    }
  }

  a.logo {
    z-index: 100001;
    display: block;
    position: absolute;
    top: -30px;
    @include responsive-from(m) {
      top: -40px;
    }
    left: 0;
    img {
      width: 120px;
      height: 120px;
      display: block;
      @include responsive-from(m) {
        width: 140px;
        height: 140px;
      }
    }
  }
  .menu {
    border: 0;
    // display: block;
    background: lighten($blue, 14%); //#5A6C79;
    height: 60px;
    width: 60px;
    border-radius: 30px;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .toggle {
      display: block;
      position: relative;
      width: 20px;
      height: 20px;
      transition: all 0.2s;
      span {
        position: absolute;
        background: #fff;
        width: 20px;
        height: 3px;
        left: 0;
        transition: all 0.2s;
        &:first-child {
          top: 3px;
        }
        &:nth-child(2) {
          top: 9px;
        }
        &:nth-child(3) {
          top: 16px;
          width: 20px;
          right: 0;
          left: unset;
        }
      }
    }
  }
  nav {
    display: block;
    width: 100vw;
    height: 100vh;
    transform: translateX(110vw);
    position: fixed;
    top: 100px;
    right: 0;
    left: 0;
    bottom: 0;

    @include responsive-from(m) {
      top: 140px;
      max-width: 480px;
      transform: translateX(500px);
      left: unset;
      border-left: 1px solid darken($menuBg, $menuDarken);
    }

    background: $menuBg;
    transition: all 0.3s;
    border-top: 1px solid darken($menuBg, $menuDarken);
    ul {
      display: block;
      li {
        a {
          text-align: left;
          color: $menuColor;
          text-decoration: none;
          text-shadow: 0 2px darken($menuBg, $menuDarken);
          display: block;
          padding: $base-spacing*3;
          border-bottom: 1px solid darken($menuBg, $menuDarken);
          &:hover {
            background: darken($menuBg, $menuDarken);
          }
        }
        ul {
          li {
            a {
              background: darken($menuBg, $menuSubDarken);
              padding-left: $base-spacing*5 !important;
            }
          }
        }
      }
    }
  }

  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"]:checked ~ nav {
    transform: translateX(0);
    @include responsive-from(m) {
      transform: translateX(0);
    }
  }

  input[type="checkbox"]:checked ~ div {
    label {
      background: #fff;
      .toggle {
        span {
          background: $red;
          &:first-child {
            top: 11px;
            transform: rotate(45deg);
          }
          &:nth-child(2) {
            left: 40vw;
          }
          &:nth-child(3) {
            top: 11px;
            width: 20px;
            right: 0;
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
}
