$blue: #2F4758;
$red: #6D0038;

$yellow: #F9D20D;
$orange: #FF9500;
$green: #30B566;
$black: #363D31;

$background: #fff; //#FDFDFD;
$box-background: #fff;

$link-color: $blue;

$border-radius: 2px;
$base-spacing: 8px;

$button-height: $base-spacing*5;
$button-radius: 4px;

$small-breakpoint: 576px;
$medium-breakpoint: 768px;
$large-breakpoint: 1020px;
$xlarge-breakpoint: 1600px;

$small-only: "(max-width: #{$medium-breakpoint})";
$medium-only: "(min-width: #{$medium-breakpoint} and max-width: #{$large-breakpoint})";
$large-only: "(min-width: #{$large-breakpoint} and max-width: #{$xlarge-breakpoint})";
$xlarge-only: "(min-width: #{$xlarge-breakpoint})";

$heading-font: 'Oswald', sans-serif;;
$body-font: 'Noto Serif', serif;

@import 'responsive';
