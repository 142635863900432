// typography
a {
  color: $link-color;
  text-decoration: underline;
  cursor: pointer;
}

p, h1, h2, h3 {
  margin: 16px 0;
  @media (min-width: $medium-breakpoint) {
    margin: 16px 0;
  }
}

.title {
  font-size: 3rem;
  line-height: 3.8rem;
  // margin-bottom: 2rem;
  // margin-top: 2rem;
  @include responsive-from(m) {
    font-size: 5rem;
    line-height: 5.4rem;
    // margin-bottom: 2rem;
    // margin-top: 4rem;
  }
}

h1, h2, h3 {
  // font-weight: 600;
  font-family: $heading-font;
  color: inherit;
  strong {
    font-weight: 600;
  }
}

h1, .h1 {
  font-size: 3rem;
  line-height: 3.4rem;
  // margin-bottom: 2rem;
  // margin-top: 4rem;
  &:first-child {
    margin-top: 0;
  }
}

h2, .h2 {
  font-size: 2.4rem;
  line-height: 3rem;
  // margin-bottom: 1.4rem;
  // margin-top: 3.2rem;
  &:first-child {
    margin-top: 0;
  }
}

h3, .h3 {
  font-size: 1.8rem;
  line-height: 2.2rem;
  &:first-child {
    margin-top: 0;
  }
}
