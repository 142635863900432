@use "sass:math";

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -8px;

  .col__inner {
    margin: 0 8px 8px 8px;
  }

  &--large {
    margin: 0 -16px;
    .col__inner {
      margin: 0 16px 16px 16px;
    }
  }

  .col--no-shrink {
    flex-shrink: 0;
  }
  .col--shrink {
    flex-shrink: 1;
  }

  .col--no-grow {
    flex-grow: 0;
  }
  .col--grow {
    flex-shrink: 0;
  }

  @for $i from 1 through 12 {
    .col__s--#{$i} {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: percentage(math.div($i, 12));
    }
  }

  @for $j from 1 through 12 {
    .col__s--order-#{$j} {
      order: $j;
    }
  }

  .col__s--hidden {
    display: none;
  }
}

@include responsive-from(m) {
  .row {
    @for $i from 1 through 12 {
      .col__m--#{$i} {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: percentage(math.div($i, 12));
      }
    }

    @for $j from 1 through 12 {
      .col__m--order-#{$j} {
        order: $j;
      }
    }

    .col__s--hidden {
      display: block;
    }

    .col__m--hidden {
      display: none;
    }
  }
}

@include responsive-from(l) {
  .row {
    @for $i from 1 through 12 {
      .col__l--#{$i} {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: percentage(math.div($i, 12));
      }
    }

    @for $j from 1 through 12 {
      .col__m--order-#{$j} {
        order: $j;
      }
    }

    .col__m--hidden {
      display: block;
    }
  }
}
