.btn {
  font-weight: 500;
  display: inline-block;
  margin: 0;
  cursor: pointer;
  text-decoration: none;
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
  background: $blue;
  border: 1px solid darken($blue, 7%);
  color: #fff;
  border: 0;
  font-family: $body-font;
  font-size: 18px;
  line-height: $button-height;
  height: $button-height;
  padding: 0 calc($button-height/3);
  border-radius: (calc($button-height/2));
  //border-radius: $button-radius;

  &.btn--rectangle {
    padding: 0 calc($button-height/3);
    border-radius: (calc($button-height/2));
    //border-radius: $button-radius;
  }

  &:focus {
    outline: 0;
  }

  &:hover {
    color: #fff;
    text-decoration: none;
  }

  &--red {
    background: $red;
    border: 1px solid darken($red, 7%);
    &:hover {
      background: darken($red, 5%);
    }
  }

  &--green {
    background: $green;
    border: 1px solid darken($green, 7%);
    &:hover {
      background: darken($green, 5%);
    }
  }
  &--orange {
    background: $orange;
    &:hover {
      background: darken($orange, 5%);
    }
  }
  &--black {
    background: $black;
    &:hover {
      background: lighten($black, 5%);
    }
  }
  &--default {
    background: lighten($background, 5%);
    &:hover {
      background: darken($background, 8%);
    }
  }



  svg, img {
    vertical-align: middle;
    margin-left: 8px !important;
  }

  @include responsive-from(m) {
    line-height: $button-height*1.2;
    height: $button-height*1.2;
    border-radius: (calc($button-height*0.6));
  }
}

.back button {
  font-weight: 500;
  display: inline-block;
  margin: 0;
  cursor: pointer;
  text-decoration: none;
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
  background: $black;
  color: #fff;
  border: 0;
  font-family: $body-font;
  font-size: 14px;
  line-height: $button-height*.8;
  height: $button-height*.8;
  padding: 0 calc($button-height/4);
  border-radius: $button-radius;

  &:focus {
    outline: 0;
  }

  &:hover {
    color: #fff;
    text-decoration: none;
  }
}
