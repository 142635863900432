@use "sass:math";

:root {
  font-size: 62.5%;
}
:root body {
  padding: 0;
  margin: 0;
  font-family: $body-font;
  font-size: 1.6rem;
  line-height: 2.6rem;
  background: $background;
  color: $black;
}

main {
  padding-top: 140px;
  @include responsive-from(m) {
    padding-top: 180px;
    min-height: 80vh;
  }
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
