
.install {
  display: block;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: $orange;
  border-bottom: 1px solid lighten($orange, 5%);
  box-shadow: 5px 5px 80px #0000001f;
  z-index: 100001;
  padding: $base-spacing*2 0;
  color: #fff;
  .container {
    display: flex;
  }
  &__icon {
    width: 80px;
    height: 80px;
    float: left;
  }
  &__action {
    padding-left: $base-spacing * 2;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  h3 {
    font-family: $heading-font;
    font-size: 16px;
    margin: 0 0 $base-spacing 0;
  }
  button {
    font-size: 14px;
    line-height: $button-height*0.8;
    height: $button-height*0.8;
    padding: 0 calc($button-height/4);
  }
  span {
    margin-left: $base-spacing;
    cursor: pointer;
    font-size: 14px;
  }
}

@include responsive-from(m) {
  .install {
    display: none;
  }
}
