// responsive utilities
@mixin responsive-from($breakpoint) {
  @if $breakpoint == "s" {
    @media (min-width: $small-breakpoint) {
      @content;
    }
  }

  @else if $breakpoint == "m" {
    @media (min-width: $medium-breakpoint) {
      @content;
    }
  }

  @else if $breakpoint == "l" {
    @media (min-width: $large-breakpoint) {
      @content;
    }
  }

  @else if $breakpoint == "xl" {
    @media (min-width: $xlarge-breakpoint) {
      @content;
    }
  }
}

@mixin responsive-until($breakpoint) {
  @if $breakpoint == "s" {
    @media (max-width: $small-breakpoint) {
      @content;
    }
  }

  @else if $breakpoint == "m" {
    @media (max-width: $medium-breakpoint) {
      @content;
    }
  }

  @else if $breakpoint == "l" {
    @media (max-width: $large-breakpoint) {
      @content;
    }
  }

  @else if $breakpoint == "xl" {
    @media (max-width: $xlarge-breakpoint) {
      @content;
    }
  }
}

@mixin responsive-at($breakpoint) {
  @if $breakpoint == "s" {
    @media #{$small-only} {
      @content;
    }
  }

  @else if $breakpoint == "m" {
    @media #{$medium-only} {
      @content;
    }
  }

  @else if $breakpoint == "l" {
    @media #{$large-only} {
      @content;
    }
  }

  @else if $breakpoint == "xl" {
    @media #{$xlarge-only} {
      @content;
    }
  }
}
